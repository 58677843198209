.chat-history-button {
    color: #CADDFF;
    background-color: transparent;
    border: 0;
    font-size: 15px;
    width: 100%;
    /* overflow: hidden; */
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px 0 10px 10px;
    /* padding: 10px; */
    overflow-x: hidden;
    text-align: left;
    border-radius: var(--button-radius);
    /* flex-grow: 1; */
    flex-shrink: 0;
    min-height: 50px;
}

.chat-history-button:hover {
    background-color: var(--color-blue-8);
}

.chat-history-button.active {
    background-color: var(--color-blue-7);
}