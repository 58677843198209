.token-summary-inner {
    background-color: var(--color-blue-3);
    color: var(--color-blue-9);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.token-summary-inner p {
    color: var(--color-blue-9);
    padding: 10px 0px 0px 10px;
    margin: 0;
    font-size: 14px;
}

.remaining {
    display: flex;
    padding: 5px 10px;
}

.remaining .item-1 {
    color: var(--color-blue-9);
    flex: 1;
    align-self: center;
    font-weight: bold;
}

.remaining .item-2 {
    background-color: var(--color-blue-5);
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 14px;
}

