.follow-up-question {
    background-color: var(--color-blue-5);
    color: white;
    border-radius: 40px;
    padding: 5px 20px;
    flex-grow: auto;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: var(--transition-time);
    box-sizing: border-box;
}

.follow-up-question:hover {
    cursor: pointer;
    background-color: var(--color-blue-7);
}