.model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    z-index: 2;
    pointer-events: none;
}

.model-2 {
    position: fixed;
    top: 0;
    left: 0;
    background-color: aqua;
    width: 60%;
    margin: 20px;
    box-sizing: border-box;
    /* pointer-events: none; */
}

.inner-model {
    display: flex;
    justify-content: center;
    /* pointer-events: none; */
}

.inner-inner-model {
    display: flex;
    gap: 20px;
    width: 60%;
    padding: 20px;
    margin-top: 20px;
    border-radius: var(--button-radius);
    position: relative;
    pointer-events: auto;
    z-index: 2;
}

.success {
    background-color: var(--color-success);
    /* background-color: aqua; */
    color: #ffffff;
}

.error {
    background-color: var(--color-error);
    color: #ffffff;
}

.warning {
    background-color: var(--color-warning);
}

.close-model {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 5px 0 0;
    cursor: pointer;
}