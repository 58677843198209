.profile-inner {
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-icon {
    flex: 10;
    margin: 0px 5px;
    /* border: 2px solid green; */
}

.name {
    /* border: 2px solid green; */
    font-size: 14px;
    flex: 80;
    max-width: 150px;
}

.name p {
    padding: 0;
    margin: 0;
    color: var(--color-blue-4);
    white-space: nowrap; /* Prevent the text from wrapping */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis; /* Show ellipsis (...) when text overflows */
}