.if-connected-green {
    background-color: var(--color-success);
}

.if-connected-red {
    background-color: var(--color-error);
}

.google-header {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 20px;
}

.connected{
    color: #ffffff;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
}
