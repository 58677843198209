.popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;
}

.popup-inner {
    border-radius: 10px;
    /* background-color: #ffffff; */
    background-color: var(--setting-background-color);
    width: 900px;
    height: 600px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}

.content-header {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    color: var(--message-text-color);
    /* border: 2px solid green; */
}

.settings-title {
    font-size: 25px;
    font-weight: 700;
    flex: 1;
    text-align: center;
}

.content-semi {
    display: flex;
    flex-direction: column;
    background-color: var(--setting-source-background-color);
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    /* border: 2px solid green; */
    height: 200px;
    color: var(--message-text-color);
}

.main-body {
    margin-top: 30px;
    /* padding-bottom: 20px; */
    display: flex;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    /* border: 2px solid green; */
    height: 100%;
}

.settings-semi-title {
    font-weight: 800;
    font-size: 16px;
    color: var(--message-text-color);
}
