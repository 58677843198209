.zone-one {
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 80px;
    height: 100vh;
    /* width: 100%; */
    box-sizing: border-box;
    /* padding-bottom: 190px; */
}

.zone-one-top {
    display: flex;
    justify-content: flex-start;
    /* justify-content: center;/ */
    /* border: 2px solid green; */
}

.zone-one-main-title {
    text-align: center;
    flex: 1;
    font-weight: 600;
    font-size: 24px;
    color: var(--message-text-color);
    /* align-self: start; */
    /* justify-self: flex-start; */
}

.zone-one-back {
    cursor: pointer;
}


.cat-one-question {
    background-color: var(--color-blue-2);
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    /* gap: 5px; */
}

/* .cat-one-question:hover {
    background-color: var(--color-blue-3);
} */

.question {
    /* border: 2px solid green; */
    flex: 1;
    color: var(--message-text-color);
}

.pencil {
    border: 1px solid var(--color-blue-5);
    border-radius: 8px;
    padding: 3px;
    margin: 0px 7px;
    background-color: var(--color-blue-2);
}

.pencil:hover {
    background-color: var(--zone-one-pencil-hover);
}


.ask {
    background-color: var(--color-blue-6);
    color: white;
    padding: 7px 15px;
    border: 0;
    font-size: 15px;
    border-radius: 10px;
    margin-right: 10px;
    /* border: 2px solid var(--color-blue-6); */
}

.ask:hover {
    /* border: 2px solid white; */
    background-color: var(--color-blue-4);
}

.zone-one-questions {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    /* border: 2px solid firebrick; */
    height: calc(100% - 250px);
    /* height: 100% - 500px; */
    overflow-y: auto;
    padding-right: 10px;
}

/* Width and background of the scrollbar */
.zone-one-questions::-webkit-scrollbar {
    width: 7px; /* Adjust the width as needed */
    background-color: transparent; /* Light gray color for the scrollbar background */
}

/* Style for the scrollbar thumb */
.zone-one-questions::-webkit-scrollbar-thumb {
    background-color: var(--color-blue-11); /* Darker gray color for the thumb */
    border-radius: 5px; /* Makes the thumb's corners rounded */
    /* border: 2px solid #f1f1f1; */
}


.zone-one-loader {
    /* border: 2px solid green; */
    display: flex;
    justify-content: center;
}