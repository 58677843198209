.status {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.status p {
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.edit-google {
    border: 2px solid var(--color-blue-5);
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    color: var(--setting-button-color);
}

.edit-google:hover {
    background-color: var(--color-blue-2);
}

.edit-mode {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
}

.save-changes {
    background-color: var(--color-blue-6);
    color: var(--color-blue-1);
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    /* color: var(--message-text-color); */
    color: white;
    border: 0;
}

.save-changes:hover {
    background-color: var(--color-blue-8);
}

