.zone-zero {
    padding: 1%;
    padding-top: 3%;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px solid green; */
}

.zero-title {
    font-weight: 700;
    font-size: 50px;
    color: var(--message-text-color);
}

.zero-sub-title {
    color: var(--message-text-color);
}

.outer-inner-graph {
    width: 600px;
    height: 450px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 50px;
    grid-template-rows: auto auto;
    justify-items: center;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 10%;
    /* border: 2px solid green; */
}

@media (max-height: 930px) {
    .outer-inner-graph {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        gap: 0px;
        width: 1200px;
    }
}

.cat-one-button {
    background-color: var(--zone-one-section-background);
    border-radius: 10px;
    border: 0;
    width: 270px;
    height: 250px;
}

.cat-one-button:hover {
    background-color: var(--zone-one-section-background-hover);
}

.cat-one-button .main-text {
    margin: 15px 0px;
    font-size: 20px;
    font-weight: bold;
    color: var(--zone-one-setion-title);
}

.cat-one-button .sub-text {
    margin: 15px 0px;
    font-size: 13px;
    color: var(--message-text-color);
}
