.light-blue-button {
    background-color: var(--color-blue-6);
    color: white;
    border: 0;
    border-radius: var(--button-radius);
    width: 100%;
    padding: 12px 0px;
}

.light-blue-button:hover {
    background-color: var(--color-blue-4);
}