.general {
    flex: 1;
    /* border: 2px solid black; */
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.theme {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--message-text-color);
}

.theme-options {
    z-index: 3;
}

.clear-chat-button {
    /* background-color: var(--color-blue-9);
    border: 2px solid var(--color-blue-5);
    border-radius: var(--button-radius);
    color: var(--color-blue-2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    cursor: not-allowed;*/
    color: var(--color-blue-4); 
    /* color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: var(--button-radius);
    /* width: 30px; */
    padding: 5px 10px;
    background-color: var(--color-blue-2);
    /* background-color: var(--color-error); */
    border: 2px solid var(--color-blue-5);
}

.clear-chat-button:hover {
    background-color: var(--color-blue-8);
}