.completion {
    background-color: var(--color-blue-2);
    /* background-color: #001B50; */
}

.text-completion p {
    /* display: inline-block; */
    /* box-sizing: border-box; */
    /* max-width: 100%; */
    /* flex: 1; */
    /* font-size: 14px; */
    /* border: 3px solid green; */
    /* word-wrap: break-word; */
    /* overflow: hidden;  */
    /* max-width: 100%; */
    /* overflow-wrap: break-word; */
}

.text-completion {
    /* width: 100%; */
    /* display: flex;
    flex-direction: column; */
    /* border: 3px solid blue; */
    /* max-width: 90%; */
    /* max-width: 90%; */
    /* flex-shrink: 0; */
    word-wrap: break-word;
    overflow: hidden;
    /* flex: 1; */
    flex-grow: 0;
    /* flex-shrink: 0; */
    /* width: 200px; */
    max-width: 100%;
}

.logo-parent {
    background-color: white;
    /* width: 35px; */
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    flex-basis: 35px;
}

.orange-logo {
    width: 35px;
}

.graph {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* max-height: 300px; */
}

/* Apply general styling to the table */
table {
    border-collapse: collapse;
    width: 100%;
    /* height:100%; */
  }
  
  /* Style the table headers */
th {
    background-color: #f2f2f2; /* Background color for header row */
    border: 1px solid #dddddd; /* Border for header cells */
    text-align: left;
    padding: 8px;
}

/* Style the table data cells */
td {
    border: 1px solid #dddddd; /* Border for data cells */
    text-align: left;
    padding: 8px;
}
  