.info {
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: rgb(143, 143, 143);
    
}

.info:before, 
.info:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid rgb(143, 143, 143);
    margin: auto;
}