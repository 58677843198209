.setting-side-bar {
    width: 150px;
    /* background-color: aqua; */
    height: 50%;
    display: flex;
    border-right: 1px solid var(--color-blue-2);
    flex-direction: column;
    gap: 10px;
    padding: 5px;
}

.setting-side-bar-button {
    /* border: 2px solid var(--color-blue-5); */
    border: 0px;
    color: var(--message-text-color);
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    display: flex;
    gap: 10px;
    background-color: transparent;
    cursor: pointer;
    width: 100%;

}

.setting-side-bar-button:hover {
    /* background-color: var(--color-blue-2); */
}