.select-options {
    border: 1px solid var(--color-blue-5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 3px;
    background-color: var(--color-blue-1);
}

.select-options p {
    padding: 0;
    margin: 5px 50px 5px 5px;
}

.unfold-more {
    margin-left: auto;
}