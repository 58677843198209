@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
    --sidebar-width: 250px;
    --color-blue-1: #EFF5FF;
    --color-blue-2: #CADDFF;
    --color-blue-3: #95BCFF;
    --color-blue-4: #4C87EC;
    --color-blue-5: #266EEA;
    --color-blue-6: #0050F0;
    --color-blue-7: #003EBB;
    --color-blue-8: #003193;
    --color-blue-9: #001B50;
    --color-blue-10: #04001A;
    --color-blue-11: #193261;
    --button-radius: 7px;
    --transition-time: 150ms;
    --color-error: #FF5757;
    --color-success: #48A05C;
    --color-warning: #FFEE56;
    --search-bar-color: white;
    --message-text-color: black;
    --setting-source-background-color: #F4F4F4;
    --setting-button-color: #0050F0;
    --setting-background-color: white;
    --tiktok-logo-search-bar-color: black;
    --zone-one-section-background: #CADDFF;
    --zone-one-section-background-hover: #95BCFF;
    --zone-one-secton-icon: #0050F0;
    --zone-one-setion-title: #003EBB;
    --zone-one-pencil-hover: #95BCFF;
}

.dark {
    --sidebar-width: 250px;
    --color-blue-1: #04001A;
    --color-blue-2: #003193;
    --color-blue-3: #95BCFF;
    --color-blue-4: #4C87EC;
    --color-blue-5: #266EEA;
    --color-blue-6: #0050F0;
    --color-blue-7: #003EBB;
    --color-blue-8: #003193;
    --color-blue-9: #001B50;
    --color-blue-10: #04001A;
    --color-blue-11: #193261;
    --button-radius: 7px;
    --transition-time: 150ms;
    --color-error: #FF5757;
    --color-success: #48A05C;
    --color-warning: #FFEE56;
    --search-bar-color: #003193;
    --message-text-color: #EFF5FF;
    --setting-source-background-color: #082869;
    --setting-button-color: #CADDFF;
    --setting-background-color: #001B50;
    --tiktok-logo-search-bar-color: white;
    --zone-one-section-background: #001B50;
    --zone-one-section-background-hover: #003193;
    --zone-one-secton-icon: #266EEA;
    --zone-one-setion-title: #95BCFF;
    --zone-one-pencil-hover: #0050F0;
}

body {
    font-family: 'Poppins';
}

.app {
    display: flex;
    height: 100vh;
    justify-content: center;
    box-sizing: border-box;
}

button {
    font-family: 'Poppins';
    cursor: pointer;
    transition: var(--transition-time);
}

.main-app-loader {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}