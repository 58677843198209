.messages-inner {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 1%;
    box-sizing: border-box;
    max-height: 100vh;
    padding-bottom: 190px;
    overflow-y: auto;
    /* min-width: 100%; */
    /* max-width: 80%; */
    /* justify-content: center; */
    align-items: center;
    /* width: 100%; */
    /* flex: 1; */

    /* word-wrap: break-word; */

    /* border: 2px solid green; */
    padding-left: 10%;
    padding-right: 10%;
    transition: 150ms;
}

.follow-ups {
    padding-bottom: 280px;
    /* background-color: aqua; */
}

.loader {
    /* padding: 20px 20%; */
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.message-parent {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;

    font-size: 14px;
    color: var(--message-text-color);
    /* max-width: 100%; */

    /* word-wrap: break-word; */
    /* overflow: hidden; */
    /* width: 100%; */
    /* max-width: 1000px; */
    /* min-width: 700px; */
    /* border: 2px solid blue; */
    /* height: 1000px; */
}

/* .messages-inner::-webkit-scrollbar { */
    /* width: 9px;               width of the entire scrollbar */
/* } */
    
/* .messages-inner::-webkit-scrollbar-track { */
    /* background: transparent;        color of the tracking area */
/* } */

/* .messages-inner::-webkit-scrollbar-thumb { */
    /* background-color: var(--color-blue-6);   */
    /* border-radius: 20px;       */
    /* border: 0px;   */
/* } */


.collapse {
    /* border: 2px solid orange; */
    flex: 1;
    /* width: 100%; */
}

.transition-group {
    /* border: 2px solid black; */
    /* flex:1; */
    width: 100%;
}