.follow-up-questions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    margin-bottom: 10px;
}

.searchbar-parent {
    position: fixed;
    bottom: 0;
    margin-bottom: 30px;
    margin-left: var(--sidebar-width);
    width: 50%;
    /* display: flex; */
    /* flex-direction: column; */
    /* border: 2px solid white;   */
    box-sizing: border-box;  
}

.searchbar {
    display: flex;
    flex-direction: column;
    background-color: var(--search-bar-color);
    border-radius: 10px;
    box-sizing: border-box;  
    
    height: 150px;
    padding: 10px;
    width: 100%;
    /* padding: 45px 30%; */
    /* z-index:a; */
}

.search-area {
    /* height: 20%; */
    width: 100%;
    font-family: 'Poppins';
    resize: none;
    font-size: 15px;
    border: 0;
    border-radius: 8px;
    outline: 0;
    background-color: var(--color-blue-1);
    color: var(--message-text-color);
}

.top {
    display: flex;
    padding-bottom: 10px;
    /* align-items: center; */
    gap: 10px;
}

.bottom {
    display: flex;
    align-items: center;
    border: 2px solid var(--color-blue-2);
    border-radius: 8px;
    background-color: var(--color-blue-1);
    transition: 250ms;
}

.bottom:focus-within {
    border: 2px solid var(--color-blue-5)
}

.submit-button {
    background-color: transparent;
    border: 0;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    outline: 0;
    border: 0;
}

.tiktok-logo {
    height: 24px;
    width: 24px;
    fill: var(--tiktok-logo-search-bar-color);
}