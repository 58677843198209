
.connect {
    padding-top:50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background-color:var(--color-blue-1);
    height: 100vh;
    box-sizing: border-box;
}
.connect-login {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sources {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.source-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connector-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:10px;
}

.connector-property-id {
    width: 200px;
}