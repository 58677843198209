.display {
    flex: 1;
    max-width: 100vw;
    background-color: var(--color-blue-1);
    /* background-color: var(--color-blue-10); */
    box-sizing: border-box;
    
}

.display-child {
    /* border: 2px solid green; */
}

.title-card {
    margin: 40px 0px 10px 0px;
}

.category {
    /* border: 2px solid blue; */
    /* margin: 50px 30% 0px 30%;  */
    padding-top: 10px;
}

/* .display-padding-without-messages-need-redoing-0 {
    padding-right: 20%;
    padding-left: 20%;
} */

/* .display-padding-without-messages-need-redoing-1 {
    padding-right: 10%;
    padding-left: 10%;
} */

/* .need-to-redo-display-loader {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
} */

.chat-log-loader {
    width: 100%;
    flex: 1;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}