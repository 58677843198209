.menubar {
    position: absolute;
    background-color: var(--color-blue-10);
    width: 100%;
    /* margin-top: 0%; */
    bottom: 100%;
    border-radius: var(--button-radius);
}

.menubar div {
    color: #ffffff;
    padding: 12px;
    display: flex;
    gap: 5px;
    cursor: pointer;
    border-radius: var(--button-radius);
}

.menubar div:hover {
    background-color: var(--color-blue-8);
}