.integration {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* padding-bottom: 20px; */
    /* box-sizing: border-box; */
    /* overflow-y: scroll; */
    max-height: 85%;
    padding: 10px;
    /* box-sizing: border-box; */
}