.property-id {
    padding: 6px 0px 6px 10px;
    font-family: 'Poppins';
    border-radius: 8px;
    border: 1px solid var(--color-blue-6);
    outline: 0;
}

.google-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 8px;
    background-color: var(--color-blue-1);
    color: var(--setting-button-color);
    border: 2px solid var(--color-blue-5);
    font-weight: 600;
}

.google-auth:hover {
    background-color: var(--color-blue-2);
}

.disabled {
    cursor: not-allowed;
    color: var(--color-blue-3);
    border: 2px solid var(--color-blue-3);

}

.message {
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.return {
    color: var(--color-success);
}

.error {
    color: var(--color-error);
}