.source {
    background-color: var(--setting-source-background-color);
    border-radius: 10px;
    padding: 10px;
    color: var(--message-text-color);
    cursor: pointer;
    transition: 150ms;
    display: flex;
}

.source-inner {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    flex: 1;
}

.source:hover {
    background-color: rgba(0, 0, 0, 0.2);
}