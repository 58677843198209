 .prompt {
    /* padding: 20px;
    display: flex;
    gap: 10px;
    font-size: 14px; */
    /* border: 2px solid green; */
    /* font-weight: 100; */
} 

.prompt-text {
    flex: 1;
}
