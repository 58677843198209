.sidebar {
    display: flex;
    background-color: #001B50;
    /* background-color: white; */
    /* flex-basis: var(--sidebar-width); */
    /* flex-grow: 1; */

    width: var(--sidebar-width);
    flex-direction: column;
    /* overflow: hidden; */
    flex-shrink: 0;
}

.logo {
    max-height: 50px;
    margin: 20px;
}

.create-chat {
    margin: 10px 12px;
}

.chat-history {
    margin: 30px 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
    /* border: 2px solid green; */
}

.clear-chat {
    margin: 10px 12px;
}

.token-summary {
    margin: 10px 12px;
}

.profile {
    margin: 10px 12px;
    position: relative;
}